import commentForm from '~/notes/components/comment_form.vue';
import NoteableNote from '~/notes/components/noteable_note.vue';
import NoteableDiscussion from '~/notes/components/noteable_discussion.vue';
import { s__ } from '~/locale';
import { createAlert } from '~/flash';

const handleContentValidationError = (originHandler, component, err) => {
  if (err.response?.data?.content_invalid) {
    const msg = s__('JH|ContentValidation|Sensitive or illegal characters involved');
    createAlert({
      message: msg,
      parent: component ? component.$el : document,
    });
  } else {
    originHandler.call(component, err);
  }
};

const { handleSaveError } = commentForm.methods;
commentForm.methods.handleSaveError = function handleSaveErrorInJH({ data, status }) {
  if (data?.content_invalid) {
    this.errors = [s__('JH|ContentValidation|Sensitive or illegal characters involved')];
  } else {
    handleSaveError.call(this, { data, status });
  }
};

const { handleUpdateError } = NoteableNote.methods;
NoteableNote.methods.handleUpdateError = function handleUpdateErrorInJH(err) {
  handleContentValidationError(handleUpdateError, this, err);
};

const { handleSaveError: handleDiscussionSaveError } = NoteableDiscussion.methods;
NoteableDiscussion.methods.handleSaveError = function handleSaveErrorInJH(err) {
  handleContentValidationError(handleDiscussionSaveError, this, err);
};
