var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-header"},[_c('gl-tabs',{attrs:{"content-class":"gl-display-none"},scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('div',{staticClass:"md-header-toolbar gl-ml-auto gl-py-2 gl-justify-content-center",class:{ 'gl-display-none!': _vm.previewMarkdown },attrs:{"data-testid":"md-header-toolbar"}},[(_vm.canSuggest)?[_c('toolbar-button',{ref:"suggestButton",staticClass:"js-suggestion-btn",attrs:{"tag":_vm.mdSuggestion,"prepend":true,"button-title":_vm.__('Insert suggestion'),"cursor-offset":4,"tag-content":_vm.lineContent,"icon":"doc-code","data-qa-selector":"suggestion_button"},on:{"click":_vm.handleSuggestDismissed}}),_vm._v(" "),(_vm.suggestPopoverVisible)?_c('gl-popover',{attrs:{"target":_vm.$refs.suggestButton.$el,"css-classes":['diff-suggest-popover'],"placement":"bottom","show":_vm.suggestPopoverVisible}},[_c('strong',[_vm._v(_vm._s(_vm.__('New! Suggest changes directly')))]),_vm._v(" "),_c('p',{staticClass:"mb-2"},[_vm._v("\n              "+_vm._s(_vm.__(
                  'Suggest code changes which can be immediately applied in one click. Try it out!'
                ))+"\n            ")]),_vm._v(" "),_c('gl-button',{attrs:{"variant":"confirm","category":"primary","size":"small","data-qa-selector":"dismiss_suggestion_popover_button"},on:{"click":_vm.handleSuggestDismissed}},[_vm._v("\n              "+_vm._s(_vm.__('Got it'))+"\n            ")])],1):_vm._e()]:_vm._e(),_vm._v(" "),_c('toolbar-button',{attrs:{"tag":"**","button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Add bold text (%{modifierKey}B)'), {
              modifierKey: _vm.modifierKey,
            }) /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,"shortcuts":_vm.$options.shortcuts.bold,"icon":"bold"}}),_vm._v(" "),_c('toolbar-button',{attrs:{"tag":"_","button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Add italic text (%{modifierKey}I)'), {
              modifierKey: _vm.modifierKey,
            }) /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,"shortcuts":_vm.$options.shortcuts.italic,"icon":"italic"}}),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('strikethrough'))?_c('toolbar-button',{attrs:{"tag":"~~","button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Add strikethrough text (%{modifierKey}⇧X)'), {
              modifierKey: _vm.modifierKey /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,
            }),"shortcuts":_vm.$options.shortcuts.strikethrough,"icon":"strikethrough"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('quote'))?_c('toolbar-button',{attrs:{"prepend":true,"tag":_vm.tag,"button-title":_vm.__('Insert a quote'),"icon":"quote"},on:{"click":_vm.handleQuote}}):_vm._e(),_vm._v(" "),_c('toolbar-button',{attrs:{"tag":"`","tag-block":"```","button-title":_vm.__('Insert code'),"icon":"code"}}),_vm._v(" "),_c('toolbar-button',{attrs:{"tag":"[{text}](url)","tag-select":"url","button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Add a link (%{modifierKey}K)'), {
              modifierKey: _vm.modifierKey,
            }) /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,"shortcuts":_vm.$options.shortcuts.link,"icon":"link"}}),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('bullet-list'))?_c('toolbar-button',{attrs:{"prepend":true,"tag":"- ","button-title":_vm.__('Add a bullet list'),"icon":"list-bulleted"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('numbered-list'))?_c('toolbar-button',{attrs:{"prepend":true,"tag":"1. ","button-title":_vm.__('Add a numbered list'),"icon":"list-numbered"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('task-list'))?_c('toolbar-button',{attrs:{"prepend":true,"tag":"- [ ] ","button-title":_vm.__('Add a checklist'),"icon":"list-task"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('indent'))?_c('toolbar-button',{staticClass:"gl-display-none",attrs:{"button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Indent line (%{modifierKey}])'), {
              modifierKey: _vm.modifierKey /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,
            }),"shortcuts":_vm.$options.shortcuts.indent,"command":"indentLines","icon":"list-indent"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('outdent'))?_c('toolbar-button',{staticClass:"gl-display-none",attrs:{"button-title":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */
            _vm.sprintf(_vm.s__('MarkdownEditor|Outdent line (%{modifierKey}[)'), {
              modifierKey: _vm.modifierKey /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,
            }),"shortcuts":_vm.$options.shortcuts.outdent,"command":"outdentLines","icon":"list-outdent"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('collapsible-section'))?_c('toolbar-button',{attrs:{"tag":_vm.mdCollapsibleSection,"prepend":true,"tag-select":"Click to expand","button-title":_vm.__('Add a collapsible section'),"icon":"details-block"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('table'))?_c('toolbar-button',{attrs:{"tag":_vm.mdTable,"prepend":true,"button-title":_vm.__('Add a table'),"icon":"table"}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('attach-file'))?_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.__('Attach a file or image'),"data-testid":"button-attach-file","category":"tertiary","icon":"paperclip"},on:{"click":_vm.handleAttachFile}}):_vm._e(),_vm._v(" "),(!_vm.restrictedToolBarItems.includes('full-screen'))?_c('toolbar-button',{staticClass:"js-zen-enter",attrs:{"prepend":true,"button-title":_vm.__('Go full screen'),"icon":"maximize"}}):_vm._e()],2)]},proxy:true}])},[_c('gl-tab',{attrs:{"title-link-class":"gl-py-4 gl-px-3 js-md-write-button","title":_vm.$options.i18n.writeTabTitle,"active":!_vm.previewMarkdown,"data-testid":"write-tab"},on:{"click":function($event){return _vm.writeMarkdownTab($event)}}}),_vm._v(" "),(_vm.enablePreview)?_c('gl-tab',{attrs:{"title-link-class":"gl-py-4 gl-px-3 js-md-preview-button","title":_vm.$options.i18n.previewTabTitle,"active":_vm.previewMarkdown,"data-testid":"preview-tab"},on:{"click":function($event){return _vm.previewMarkdownTab($event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }